<template>
    <input
        type="date"
        :value="value"
        :class="{ '--empty': !value && !focused }"
        @input="e => $emit('input', e.target.value)"
        @focusin="focused = true"
        @focusout="focused = false"
        v-bind="$attrs"
    />
</template>

<script>
export default {
    name: "InputDate",
    props: {
        value: {
            type: String
        }
    },
    data() {
        return {
            focused: false
        };
    }
};
</script>
