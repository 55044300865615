<template>
    <div class="a-submitButton">
        <button class="a-submitButton__button" v-bind="$attrs" v-on="$listeners" ref="button">
            <span ref="text" class="a-submitButton__text" :class="{'--loading' : loading}">{{ text }}</span>
        </button>
    </div>
</template>

<script>
export default {
    name: "SubmissionButton",
    props: {
        text: {
            type: String,
            default: "Submit"
        },
        done: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
     data() {
        return {
            doneTimeout: ''
        };
    },
    watch: {
        done(val) {
            this.$refs.button.classList.remove('--loading');
            this.$refs.button.classList.add('--done');
            this.doneTimeout = setTimeout(() => {
                this.$emit('done');
            }, 300);
        },
        loading(val) {
            if (val === true) {
                this.$refs.text.remove();
                this.$refs.button.classList.add('--loading');
            }
        }
    }
};
</script>

