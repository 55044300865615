<template></template>

<script>
    export default {
        name: "VimeoPlayer",
        mounted() {
            const playerScript = document.createElement('script');
            playerScript.setAttribute('src', 'https://player.vimeo.com/api/player.js');
            document.head.appendChild(playerScript);
        }
    }
</script>