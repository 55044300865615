<template>
    <form class="pricing__form" @submit.prevent="handleSubmit" ref="form">
        <input type="hidden" v-model="utm_source" name="utm_source">
        <input type="hidden" v-model="utm_medium" name="utm_medium">
        <input type="hidden" v-model="utm_campaign" name="utm_campaign">
        <input type="hidden" v-model="utm_content" name="utm_content">
        <input type="hidden" v-model="utm_term" name="utm_term">

        <div>
            <h4 class="pricing__sectionHeading">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                Contact Info
            </h4>
            <div class="pricing__formGridRow">
                <label class="left">
                    Name* <input type="text" name="name" placeholder="Jane Smith" :disabled="isSubmitted || isLoading" required />
                </label>
                <label class="right">
                    Organization* <input type="text" name="organization_name" placeholder="Organization Name" :disabled="isSubmitted || isLoading" required />
                </label>
                <label class="left">
                    Email* <input type="email" name="email" placeholder="jane@example.com" :disabled="isSubmitted || isLoading" required />
                </label>
                <label class="right">
                    Phone* <input type="text" name="phone" placeholder="(555) 555-5555" :disabled="isSubmitted || isLoading" required />
                </label>
            </div>
        </div>


        <div class="pricing__submitWrap">
            <submission-button type="submit" text="Download Pricing Sheet" :loading="isLoading" :key="submitKey" :done="isSubmitted" @done="downloadFile"></submission-button>
            <transition name="fade">
                <div v-if="showSuccess">
                    <p>
                       Thank you! Your pricing sheet is downloaded to your device.
                    </p>
                </div>
            </transition>
        </div>
        <div v-if="errorMessage" class="o-form__errors">
            <h2 class="o-form__errorHeading">{{ errorMessage }}</h2>
            <ul v-if="Array.isArray(errors) && errors.length > 0" class="o-form__errorList">
                <li v-for="error in errors" class="o-form__errorItem" >{{ error }}</li>
            </ul>
        </div>
    </form>
</template>

<script>
import InputDate from "./form/InputDate.vue";
import SubmissionButton from "./form/SubmissionButton";
import utmMixin from "../utmMixin";

export default {
    name: "PricingForm",
    mixins: [utmMixin],
    components: {
        InputDate,
        SubmissionButton
    },
    props: {
        pricing_pdf: {
            type: String,
            required: true
        },
        csrf: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            delayConfirm: true,
            errors: null,
            errorMessage: null,
            isLoading: false,
            isSubmitted: false,
            showSuccess: false,
            submitKey: 1,
        };
    },
    methods: {
         buildPayload() {
            const payload = {};
            const formData = new FormData(this.$refs.form);
            formData.forEach((value, name) => {
                payload[name] = value;
            });

            return payload;
        },
        handleResponse(response) {
            this.isLoading = false;
            this.isSubmitted = response.ok;
            if (!response.ok) {
                this.handleError(response);
            }
        },
        handleError(response) {
            response.json().then(error => {
                this.errorMessage = error.message;
                this.errors = error.errors ? Object.values(error.errors).map(item => item[0]) : null;
                this.submitKey++; // force submit button re-render to initial state.
            });
        },
        handleSubmit(e) {
            e.preventDefault();
            if (this.isSubmitted || this.isLoading) {
                 return;
            }

            this.isLoading = true;
            this.errors = null;
            this.errorMessage = null;

            // forces a minimum delay on the UI processing state to prevent jerky animations.
            const delay = setTimeout(() => {
                this.delayConfirm = false;
            }, 1000);

            fetch("/api/pricing", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": this.csrf
                },
                body: JSON.stringify(this.buildPayload())
            })
            .then(response => {
                // the minimum delay has not expired so we will wait 1 more second before updating UI.
                if (this.delayConfirm) {
                    clearTimeout(delay);
                    setTimeout(() => {
                        this.handleResponse(response);
                    }, 1000);
                } else {
                    this.handleResponse(response);
                }
            });
        },
        downloadFile() {
            this.showSuccess = true;
            const url = this.pricing_pdf;
            const filename = 'acceptdpricingsheetpacket.pdf';

            fetch(url).then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            });
        },
    }
};
</script>
