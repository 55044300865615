<template>
  <form class="o-form" @submit.prevent="(event) => !isSearching && throttledInputHandler(event)">
    <div class="o-form__fieldGroup">
      <div class="o-form__fieldOfOne flex">
        <input class="shadow-md o-directory__heading-search-input"
               name="query"
               placeholder="Find an organization to apply to..." type="text"/>
        <button :class="{ 'a-button--state-b': isSearching }" class="shadow-md a-button a-button--primary a-button--preserve-width o-directory__heading-search-button" type="submit">
          <span class="a-button__content-a">Search</span>

          <span class="a-button__content-b">
    <svg class="icon icon--is-spinning" aria-hidden="true" viewBox="0 0 16 16"><title>Loading</title><g stroke-width="1"
                                                                                                        fill="currentColor"
                                                                                                        stroke="currentColor"><path
        d="M.5,8a7.5,7.5,0,1,1,1.91,5" fill="none" stroke="currentColor" stroke-linecap="round"
        stroke-linejoin="round"></path></g></svg>
  </span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import throttle from "lodash.throttle";

export default {
  name: "DirectorySearch",
  data: () => ({
    throttledInputHandler: Function.prototype
  }),
  props: {
    isSearching: {
      default: false,
      type: Boolean
    },
    wait: {
      default: 1000,
      type: Number
    }
  },
  created() {
    this.throttledInputHandler = throttle((event) => {
      const input = event.target.elements.namedItem('query');
      if (input && input.value) {
        this.$emit('search-change', input.value);
      }
    }, this.wait)
  },
  destroyed() {
    this.throttledInputHandler.cancel();
  }
}
</script>