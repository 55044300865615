// This is all you.
export function getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
        let [key,value] = el.split('=');
        cookie[key.trim()] = value;
    })
    return cookie[cookieName];
};

export function setCookie(name, value, days) {
  var date = new Date();
  date.setTime(date.getTime() + (days*24*60*60*1000));
  var expires = "; expires=" + date.toGMTString();
  document.cookie = name + "=" + value + expires;
};