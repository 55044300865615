<template>
  <div
      :style="{ height, width: computedWidth }"
      :class="`ske ske--${representation}`"
  />
</template>

<script>
// Original component concept by Markus Oberlehner
// https://markus.oberlehner.net/blog/skeleton-loading-animation-with-vue/
// and Skeleton Screen styling from Codyhouse Framework
// https://codyhouse.co/ds/components/app/skeleton-screen--example-3

export default {
  name: 'Skeleton',
  props: {
    maxWidth: {
      default: 100,
      type: Number,
    },
    minWidth: {
      default: 80,
      type: Number,
    },
    height: {
      default: '1em',
      type: String,
    },
    width: {
      // Make it possible to define a fixed
      // width instead of using a random one.
      default: null,
      type: String,
    },
    representation: {
      // May be one of text, square, circle, rect-1:1, rect-16:9, rect-4:3
      default: "text",
      type: String
    }
  },
  computed: {
    computedWidth() {
      // Either use the given fixed width or a random width
      // between the given min and max values.
      return this.width || `${Math.floor((Math.random() * (this.maxWidth - this.minWidth)) + this.minWidth)}%`;
    },
  },
};
</script>