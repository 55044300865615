<template>
    <div>

    </div>
</template>

<script>
    export default {
        name: "PagePositionIndicator",
        data() {
            return {
                windowPosition: 0
            }
        },
        mounted() {
            this.$nextTick(function () {
                window.addEventListener('scroll', () => {
                    let scrollPos = window.scrollY;
                    let winHeight = window.innerHeight;
                    let docHeight = document.documentElement.scrollHeight;
                    let position = 100 * scrollPos / (docHeight - winHeight);
                    this.windowPosition = Math.round(position);
                    console.log(this.windowPosition);
                })
            })

        },
    }
</script>

<style scoped>

</style>