<template>
  <div class="m-organizationCard padding-md padding-bottom-lg shadow-sm" :class="{'shadow-md-hover': !loading }">
    <div class="m-organizationCard__image">
      <skeleton v-if="loading" representation="square"/>
      <slot v-else name="image"/>
    </div>
    <div class="m-organizationCard__title margin-bottom-lg">
      <h3 class="text-title">
        <skeleton v-if="loading" representation="text"/>
        <slot v-else name="title"/>
      </h3>
      <p class="text-caption margin-bottom-0">
        <skeleton v-if="loading" :min-width="40" :max-width="60" representation="text"/>
        <slot v-else name="address"/>
      </p>
    </div>
    <div class="m-organizationCard__type margin-bottom-md">
      <span class="text-overline">
        <skeleton :width="`${'Organization Type'.length}ch`" v-if="loading" representation="text"/>
        <template v-else>Organization Type</template>
      </span>
      <p class="text-body color-contrast-high margin-bottom-0">
        <skeleton v-if="loading" representation="text"/>
        <slot v-else name="organization-types"/>
      </p>
    </div>
    <div class="m-organizationCard__disciplines">
      <span class="text-overline">
        <skeleton :width="`${'Disciplines'.length}ch`" v-if="loading" representation="text"/>
        <template v-else>Disciplines</template>
      </span>
      <p class="text-body color-contrast-high margin-bottom-0">
        <skeleton v-if="loading" representation="text"/>
        <slot v-else name="disciplines"/>
      </p>
    </div>
  </div>
</template>

<script>
import Skeleton from "./Skeleton.vue";

export default {
  name: "DirectoryCard",
  components: {
    Skeleton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>