<template></template>

<script>
    export default {
        name: "FormSubmitTrack",
        props: {
            formName: String,
        },
        mounted: function() {
            if (typeof gtag !== "undefined" && this.formName) {
                gtag('event', 'form_submit', {
                    'name': this.formName,
                })
            }
        }
    }
</script>
