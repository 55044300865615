import Vue from "vue";
import Directory from "./components/Directory.vue";
import FormChangeTrack from "./components/FormChangeTrack.vue";
import FormSubmitTrack from "./components/FormSubmitTrack.vue";
import IntersectionAnimate from "./components/IntersectionAnimate.vue";
import PagePositionIndicator from "./components/PagePositionIndicator.vue";
import ParallaxPhotos from "./components/ParallaxPhotos.vue";
import RequestDemo from "./components/RequestDemo.vue";
import RequestDemoSubmitted from "./components/RequestDemoSubmitted.vue";
import PricingForm from "./components/PricingForm.vue";
import TheTeam from "./components/TheTeam.vue";
import VimeoPlayer from "./components/VimeoPlayer.vue";
import { setCookie } from "./site";

new Vue({
    el: "#app",
    data() {
        return {
            currentDesktopSubmenu: undefined,
            showMobileMenu: false,
        };
    },
    mounted() {
        this.setTransparency(window.scrollY);

        this.$nextTick(function () {
            // exit menu on window resize
            window.addEventListener("resize", () => {
                // this.showMobileMenu = false
            });

            // make nav opaque when scrolling down
            window.addEventListener("scroll", () => {
                this.setTransparency(window.scrollY);
            });
        });
    },
    methods: {
        toggleMobileMenu: function () {
            document.body.style.overflow = (this.showMobileMenu =
                !this.showMobileMenu)
                ? "hidden"
                : "auto";
        },
        showDesktopSubmenu: function (submenu) {
            this.currentDesktopSubmenu = submenu;
        },
        closeAllMenus: function () {
            this.currentDesktopSubmenu = undefined;
            this.showMobileMenu = false;
            document.body.style.overflow = "auto";
        },
        setTransparency(position) {
            if (position > 0) {
                document.getElementsByClassName(
                    "o-nav"
                )[0].style.backgroundColor = "rgba(255,255,255,1.0)";
            } else {
                document.getElementsByClassName(
                    "o-nav"
                )[0].style.backgroundColor = "rgba(255,255,255,0.9)";
            }
        },
    },
    components: {
        Directory,
        FormSubmitTrack,
        FormChangeTrack,
        IntersectionAnimate,
        PagePositionIndicator,
        ParallaxPhotos,
        RequestDemo,
        RequestDemoSubmitted,
        TheTeam,
        VimeoPlayer,
        PricingForm,
    },
});

import Glide from "@glidejs/glide";

if (document.getElementsByClassName("glide").length) {
    new Glide(".glide", { type: "carousel", animationDuration: 500 }).mount();
}

function getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}

["utm_source", "utm_campaign", "utm_medium", "utm_content", "utm_term"].forEach(
    (field) => {
        const value = getUrlParameter(field);
        if (value) {
            setCookie(field, value, 90);
            const inputs = document.querySelectorAll(`input[name="${field}"]`);
            if (inputs && inputs.length) {
                inputs.forEach((input) => {
                    input.value = value;
                });
            }
        }
    }
);
