<template>
  <div>
    <section
        class="o-directory__heading padding-top-xxl padding-bottom-xxl margin-bottom-lg margin-bottom-xxxl@lg bg-desaturated-green">
      <div class="container o-directory__heading-container">
        <img class="o-directory__heading-image" src="/img/directory-search@2x.jpeg" alt="College student holding phone">
        <div class="o-directory__heading-description margin-bottom-lg">
          <h2 class="text-headline-1 margin-bottom-sm">Directory Search</h2>
          <p class="text-body">Find and apply to over 750 performing arts colleges and organizations. Search here or sign-up to
            browse them all.</p>
        </div>
        <div class="o-directory__heading-search">
          <directory-search :is-searching="isSearching" v-on:search-change="onSearchChange"></directory-search>
        </div>
      </div>
    </section>
    <section class="padding-bottom-xxxl padding-top-xxxl">
      <div class="container">
        <transition name="fade" mode="out-in">
          <h2 style="min-height: 3em;" v-if="results === null && !isSearching" key="sponsors"
              class="text-headline-3 margin-bottom-lg text-center margin-left-auto margin-right-auto">The world's top
            arts organizations choose to partner with Acceptd.</h2>
          <h2 style="min-height: 3em;" key="searching" v-if="isSearching"
              class="text-headline-3 margin-bottom-lg text-center margin-left-auto margin-right-auto">Searching from
            over 750 performing arts colleges and organizations...</h2>
          <h2 style="min-height: 3em;" key="results" v-if="results && results.length" class="text-headline-3 margin-bottom-lg text-center margin-left-auto margin-right-auto">Here are a just a few to get you started...</h2>
        </transition>
        <transition name="fade-up" mode="out-in">
          <directory-results key="sponsors" :organizations="sponsors" v-if="!results && !isSearching"></directory-results>
          <directory-results key="results" :organizations="results" v-if="(results && results.length) || isSearching"></directory-results>
          <div key="empty" v-if="!isSearching && results && !results.length" class="flex align-center justify-center" style="min-height: 20vh;width: 66%;margin-left: auto;margin-right: auto;">
            <div>
              <h3 class="text-headline-3 margin-bottom-sm">Sorry, we couldn't find any matches...</h3>
              <p class="text-body">But don't be discouraged, we have powerful filtering tools to enhance your searching ability within Acceptd. <a href="https://app.getacceptd.com/signup">Sign up for your free account</a> to find organizations more easily.</p>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="(!isSearching && sponsors && sponsors.length && !results) || (!isSearching && results && results.length)" class="margin-top-xxxl flex flex-column align-center">
            <h2 class="text-headline-2 text-center margin-bottom-sm">But that's not all...</h2>
            <p class="text-deck text-center margin-bottom-xl">So many great performing arts colleges and organizations use Acceptd. Sign up to browse them all!</p>
            <a class="a-button --shadow --bg-deep-orange" href="https://app.getacceptd.com/signup">Join for FREE</a>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import DirectoryResults from "./DirectoryResults.vue";
import DirectorySearch from "./DirectorySearch.vue";

export default {
  name: "Directory",
  components: {
    DirectoryResults,
    DirectorySearch,
  },
  data: () => ({
    sponsors: null,
    results: null,
    isSearching: false
  }),
  created() {
    this.fetchSponsors().then((results) => {
      this.sponsors = results.data
    });
  },
  methods: {
    onSearchChange(query) {
      this.results = null;
      this.isSearching = true;
      this.fetchResults(query).then((results) => {
        this.isSearching = false;
        this.results = results.data;
      })
    },
    fetchResults(query) {
      return fetch(`https://app.getacceptd.com/api/marketing-organizations?search=${encodeURI(query)}`, {
        mode: 'cors',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      })
        .then(response => response.json());
    },
    fetchSponsors() {
      return fetch(`https://app.getacceptd.com/api/marketing-organizations?sponsored=true`, {
        mode: 'cors',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      })
          .then(response => response.json());
    }
  },
}
</script>
