<template>
    <ul class="list-unstyled auto-grid" v-if="organizations === null" key="loading">
      <li v-for="n in 6" :key="n">
        <directory-card loading/>
      </li>
    </ul>
    <ul class="list-unstyled auto-grid" v-else key="loaded">
      <li v-for="organization in organizations" :key="organization.short_url">
        <a :href="organization.url" target="_blank" class="decoration-none" :aria-label="`Read more about ${organization.name}`">
          <directory-card>
            <template #image><img :src="organization.thumbnail_uri" :alt="`Logo for ${organization.name}`"/></template>
            <template #title>{{ organization.name }}</template>
            <template #address>{{ address(organization) }}</template>
            <template #organization-types>{{ organization.organization_type.join(", ") }}</template>
            <template #disciplines>{{ organization.discipline.join(", ") }}</template>
          </directory-card>
        </a>
      </li>
    </ul>
</template>

<script>
import DirectoryCard from "./DirectoryCard.vue";

export default {
  name: "DirectoryResults",
  components: {DirectoryCard},
  props: {
    organizations: {
      default: null,
      type: Array
    },
  },
  methods: {
    address({ location }) {
      return [location.locality, location.administrative_area_level_1, location.country].filter(i => !!i).join(", ")
    }
  }
}
</script>