<template>
    <form class="request-demo__form" @submit.prevent="handleSubmit" ref="form">
        <input type="hidden" v-model="utm_source" name="utm_source">
        <input type="hidden" v-model="utm_medium" name="utm_medium">
        <input type="hidden" v-model="utm_campaign" name="utm_campaign">
        <input type="hidden" v-model="utm_content" name="utm_content">
        <input type="hidden" v-model="utm_term" name="utm_term">
        <!-- interested in? -->
        <div>
            <h2 class="request-demo__formTitle">What are you looking for?</h2>
            <small>Select at least 1</small>
            <div>
                <label class="request-demo__interestCheckbox">
                    <input type="checkbox" v-model="interestedInApplications" name="interested_applications" :disabled="isSubmitted || isLoading" :required="!this.interestedInMarketing && !this.interestedInApplications" />
                    Help with applications and/or audition resources
                </label>
            </div>
            <div>
                <label class="request-demo__interestCheckbox">
                    <input type="checkbox" v-model="interestedInMarketing" name="interested_marketing" :disabled="isSubmitted || isLoading" :required="!this.interestedInMarketing && !this.interestedInApplications" />
                    Marketing & recruiting resources
                </label>
            </div>
        </div>

        <!-- user info -->
        <div>
            <h4 class="request-demo__sectionHeading">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                Contact Info
            </h4>
            <div class="request-demo__formGridRow">
                <label class="left">
                    Full Name* <input type="text" name="name" placeholder="Jane Smith" :disabled="isSubmitted || isLoading" required />
                </label>
                <label class="right">
                    Role Within Organization* <input type="text" name="user_role" placeholder="Program Administrator" :disabled="isSubmitted || isLoading" required />
                </label>
                <label class="left">
                    Email* <input type="email" name="email" placeholder="jane@example.com" :disabled="isSubmitted || isLoading" required />
                </label>
                <label class="right">
                    Phone* <input type="text" name="phone" placeholder="(614) 499-2738" :disabled="isSubmitted || isLoading" required />
                </label>
                <label class="left">
                    How did you hear about us?*
                    <select class="request-demo__referralTextarea" name="hear_about" required>
                        <option value="" selected disabled hidden>Please select one</option>
                        <option value="Referral">Referral</option>
                        <option value="Google">Google</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Email">Email</option>
                        <option value="Other">Other</option>
                    </select>
                </label>
            </div>
        </div>

        <!-- org info -->
        <div>
            <h4 class="request-demo__sectionHeading">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                </svg>
                Organization Info
            </h4>
            <div class="request-demo__formGridRow">
                <label class="left">
                    Name* <input type="text" name="organization_name" placeholder="Organization Name" :disabled="isSubmitted || isLoading" required />
                </label>
                <label class="right">
                    Number of applications last cycle <input type="number" name="number_applications" placeholder="1000" :disabled="isSubmitted || isLoading" />
                </label>
                <label class="left">
                    Target Open Date for Applications
                    <input-date
                        name="open_date"
                        v-model="openDate"
                        :disabled="isSubmitted || isLoading"
                    />
                </label>
                <label class="full">
                    Current process to handle applications
                    <textarea name="current_process" placeholder="All applications are handled via paper forms and email..." :disabled="isSubmitted || isLoading" ></textarea>
                </label>
                <label v-if="interestedInMarketing" class="left">
                    Marketing Budget
                    <input type="text" name="marketing_budget" placeholder="$5,000" :disabled="isSubmitted || isLoading" />
                </label>
                <label v-if="interestedInMarketing" class="full">
                    Marketing Goals
                    <textarea name="marketing_goals" placeholder="Increase applications, reach a specific demographic..." :disabled="isSubmitted || isLoading" ></textarea>
                </label>
                <label class="full">
                    Anything else we should know?
                    <textarea name="additional_info" placeholder="We have a complex admissions process..." :disabled="isSubmitted || isLoading"></textarea>
                </label>
            </div>
        </div>

        <div class="request-demo__submitWrap">
            <submission-button type="submit" text="REQUEST DEMO" :loading="isLoading" :key="submitKey"></submission-button>
        </div>
        <div v-if="errorMessage" class="o-form__errors">
            <h2 class="o-form__errorHeading">{{ errorMessage }}</h2>
            <ul v-if="Array.isArray(errors) && errors.length > 0" class="o-form__errorList">
                <li v-for="error in errors" class="o-form__errorItem" >{{ error }}</li>
            </ul>
        </div>
    </form>
</template>

<script>
import InputDate from "./form/InputDate.vue";
import SubmissionButton from "./form/SubmissionButton";
import utmMixin from "../utmMixin";

export default {
    name: "RequestDemo",
    mixins: [utmMixin],
    components: {
        InputDate,
        SubmissionButton
    },
    props: {
        csrf: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            delayConfirm: true,
            errors: null,
            errorMessage: null,
            interestedInApplications: false,
            interestedInMarketing: false,
            isLoading: false,
            isSubmitted: false,
            openDate: null,
            submitKey: 1, // used to force re-render of submit button on error
        };
    },
    computed: {
        isFormValid() {
           return (this.interestedInMarketing || this.interestedInApplications);
        }
    },
    methods: {
        buildPayload() {
            const payload = {};
            const formData = new FormData(this.$refs.form);
            formData.forEach((value, name) => {
                if (name === "interested_applications" || name === "interested_marketing") {
                    payload[name] = Boolean(value);
                } else {
                    payload[name] = value;
                }
            });

            return payload;
        },
        handleResponse(response) {
            this.isLoading = false;
            this.isSubmitted = response.ok;
            if (!response.ok) {
                this.handleError(response);
            } else {
                window.location.href = 'request-demo-submitted';
            }
        },
        handleError(response) {
            response.json().then(error => {
                this.errorMessage = error.message;
                this.errors = error.errors ? Object.values(error.errors).map(item => item[0]) : null;
                this.submitKey++; // force submit button re-render to initial state.
            });
        },
        handleSubmit(e) {
            e.preventDefault();
            if (this.isSubmitted || this.isLoading) {
                 return;
            }

            this.isLoading = true;
            this.errors = null;
            this.errorMessage = null;

            // forces a minimum delay on the UI processing state to prevent jerky animations.
            const delay = setTimeout(() => {
                this.delayConfirm = false;
            }, 1000);

            fetch("/api/request-demo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": this.csrf
                },
                body: JSON.stringify(this.buildPayload())
            })
            .then(response => {
                // the minimum delay has not expired so we will wait 1 more second before updating UI.
                if (this.delayConfirm) {
                    clearTimeout(delay);
                    setTimeout(() => {
                        this.handleResponse(response);
                    }, 1000);
                } else {
                    this.handleResponse(response);
                }
            });
        }
    }
};
</script>
