<template>
    <div class="o-meetTheTeam">
        <h2 class="o-meetTheTeam__header" v-text="header"></h2>
        <!--Team list-->
        <div class="o-meetTheTeam__teamList">

            <!--Member-->
            <a v-for="(member,index) in teamData"
               @click="selectMember(index)"
               class="o-meetTheTeam__member"
               :class="[{'--inactive': (selected !== undefined && selected !== index)},{'--active':selected===index}]">
                <div class="o-meetTheTeam__memberContainer">

                    <!--Member Headshots-->
                    <img class="o-meetTheTeam__primaryShot"
                         loading="lazy"
                         :src="member.primary_headshot.permalink"
                         :alt="member.first_name + '' +  member.last_name">
                    <img class="o-meetTheTeam__secondaryShot"
                         loading="lazy"
                         :class="{'--active': selected===index}"
                         :src="member.secondary_headshot.permalink"
                         :alt="member.first_name + '' + member.last_name" >

                    <!--Member Info-->
                    <div class="o-meetTheTeam__memberInfo"
                         :class="[{'--active': selected === index}, '--' + member.background_color.value]"
                         v-show="selected===index">
                        <h2 class="o-meetTheTeam__memberName">{{ member.first_name }} {{ member.last_name }}</h2>
                        <h3 class="o-meetTheTeam__memberTitle">{{ member.job_title }}</h3>
                        <p class="o-meetTheTeam__memberDescription">
                            Q. {{ member.question }} <br>
                            A. {{ member.answer }}
                        </p>
                        <!--                        <a class="o-meetTheTeam__memberButton a-button &#45;&#45;light">Meet {{ member.firstName }}</a>-->
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TheTeam",
        props: {
          teamData: {
          },
          header: String,
        },
        data() {
            return {
                selected: undefined
            }
        },
        methods: {
            selectMember: function (index) {
                this.selected = (this.selected === index) ? undefined : index;
            }
        }
    }
</script>
