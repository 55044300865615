import { getCookie } from "./site";

export default {
  data() {
    return {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_content: null,
      utm_term: null
    };
  },
  mounted() {
    let params = (new URL(document.location)).searchParams;
    this.utm_source = params.get('utm_source') || getCookie('utm_source');
    this.utm_medium = params.get('utm_medium') || getCookie('utm_medium');
    this.utm_campaign = params.get('utm_campaign')|| getCookie('utm_campaign');
    this.utm_content = params.get('utm_content') || getCookie('utm_content');
    this.utm_term = params.get('utm_term') || getCookie('utm_term');
  }
}
