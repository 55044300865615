<template>
    <div
        v-if="images && images.length > 0"
        style="overflow-y: visible; position: relative"
    >
        <div class="parallaxCollage">
            <div class="rellax" data-rellax-speed="0.0">
                <img
                    class="parallaxImage08"
                    :src="images[0].permalink"
                    alt="Acceptd Team"
                />
            </div>
            <div class="rellax" data-rellax-speed="-0.5">
                <img
                    class="parallaxImage07"
                    :src="images[1].permalink"
                    alt="Acceptd Team"
                />
            </div>
            <div class="rellax" data-rellax-speed="-0.85">
                <img
                    class="parallaxImage09"
                    :src="images[2].permalink"
                    alt="Acceptd Team"
                />
            </div>
            <div class="rellax" data-rellax-speed="-0.6">
                <img
                    class="parallaxImage06"
                    :src="images[3].permalink"
                    alt="Acceptd Team 06"
                />
            </div>
            <div class="rellax" data-rellax-speed="0.5">
                <img
                    class="parallaxImage05"
                    :src="images[4].permalink"
                    alt="Acceptd Team"
                />
            </div>
            <div class="rellax" data-rellax-speed="0.1">
                <img
                    class="parallaxImage04"
                    :src="images[5].permalink"
                    alt="Acceptd Team"
                />
            </div>
            <div class="rellax" data-rellax-speed="-0.75">
                <img
                    class="parallaxImage03"
                    :src="images[6].permalink"
                    alt="Acceptd Team"
                />
            </div>
            <div class="rellax" data-rellax-speed="-0.9">
                <img
                    class="parallaxImage02"
                    :src="images[7].permalink"
                    alt="Acceptd Team"
                />
            </div>
            <div class="rellax" data-rellax-speed="-1">
                <img
                    class="parallaxImage01"
                    :src="images[8].permalink"
                    alt="Acceptd Team"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Rellax from "rellax";

export default {
    name: "ParallaxPhotos",
    props: {
        images: Array,
    },
    mounted() {
        new Rellax(".rellax", {
            center: true,
        });
    },
};
</script>

<style lang="scss" scoped>
.parallaxCollage {
    height: 65vw;
    position: relative;
    overflow-x: hidden;
}

.rellax {
    img {
        position: absolute;
    }
}

.parallaxImage01 {
    transform: translate(-21vw, -5vw);
    width: 30vw;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}

.parallaxImage02 {
    transform: translate(4vw, 4vw);
    width: 37vw;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}

.parallaxImage03 {
    transform: translate(-19vw, 27vw);
    width: 28vw;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}

.parallaxImage04 {
    transform: translate(22vw, 36vw);
    width: 16vw;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}

.parallaxImage05 {
    transform: translate(34vw, 28vw);
    width: 31vw;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}

.parallaxImage06 {
    transform: translate(40vw, 0vw);
    width: 32vw;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}

.parallaxImage07 {
    transform: translate(66vw, 4vw);
    width: 41vw;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}

.parallaxImage08 {
    transform: translate(69vw, 35vw);
    width: 34vw;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}

.parallaxImage09 {
    transform: translate(89vw, 17vw);
    width: 27vw;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.2);
}
</style>
