<template>
    <div v-bind:class="[ willAnimate ? animation : '']">
        <slot></slot>
    </div>
</template>


<script>
    export default {
        name: 'IntersectionAnimate',
        props: {
            "shouldAnimate": Boolean,
            "animation": String,
        },
        data: () => ({ observer: null }),
        computed: {
            canAnimate: () => typeof IntersectionObserver !== undefined,
            willAnimate: function() {
                return this.shouldAnimate && this.canAnimate;
            }
        },
        mounted () {
            this.$nextTick(() => {
                if (this.willAnimate) {
                    this.observer = new IntersectionObserver((elements) => {
                        elements.forEach((element) => {
                            if (element.isIntersecting) {
                                this.$el.classList.add('--active');
                            }
                        }, { threshold: 0.5 });
                    });
                    this.observer.observe(this.$el);
                }
            })
        },
        destroyed() {
            this.observer.disconnect();
        }
    }
</script>
