<template></template>

<script>
    export default {
        name: "FormChangeTrack",
        props: {
            formSelector: String,
        },
        mounted: function() {
            if (!this.formSelector) return;

            let formBegin = false;
            const contactForm = document.querySelector("." + this.formSelector);
            if (contactForm && typeof gtag !== "undefined") {
                contactForm.addEventListener('change', function(e) {
                    if (!formBegin) {
                        gtag('event', 'form_start', {
                            'name': 'Contact Form'
                        });
                        formBegin = true;
                    }

                    gtag('event', 'form_field_complete', {
                        'name': e.target.getAttribute("name") || "unnamed field",
                    });
                });
            }
        }
    }
</script>
