<template>
    <div class="a-submitButton">
        <div class="a-submitButton__button --done" ref="button">
            <span class="a-submitButton__icon --done" ref="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
                    <polyline points="20 6 9 17 4 12"/>
                </svg>
            </span>
        </div>
        <p class="request-demo__successMessage">Message received! We will be in touch soon.</p>
    </div>
</template>
<script>

export default {
    name: "RequestDemoSubmitted",
};
</script>
